.login-container {
  width: 100vw;
  height: 100vh;
  background-color: #181818;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-head {
    color: white;
    padding: 1rem;
  }
  &-link {
    color: white;
    background-color: green;
    text-decoration: none;
    padding: 0.8rem 4rem;
    border-radius: 0.7rem;
  }
}
