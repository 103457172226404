.sidebar {
  width: 100%;
  height: 100vh;
  background-color: black;
  padding: 1rem;
  color: white;
  &-menu {
    margin-top: 2rem;
    &-list {
      list-style: none;
      cursor: pointer;
      padding: 1rem 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 100vh;
    background-color: black;
    padding: 1rem;
    color: white;
    h1 {
      font-size: 12px;
    }
    &-menu {
      margin-top: 2rem;
      &-list {
        list-style: none;
        cursor: pointer;
        font-size: 8px;
        font-weight: bold;
        padding: 1rem 0;
      }
    }
  }
}
