.content {
  width: 80vh;

  &-wrapper {
    width: 100vw;
    height: 90vh;
    padding: 2rem;
    overflow-y: scroll;
  }
  &-header {
    color: white;
    margin: 1rem 0;
  }
  &-playlist {
    width: 100%;
    display: flex;
    overflow-x: scroll;
  }
  &-player {
    position: fixed;
    padding: 0 1rem;
    width: 80%;
    height: 4rem;
    bottom: 0;
    overflow: hidden;
  }
  &-search {
    position: absolute;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &-close {
      position: absolute;
      right: 12%;
      top: 5%;
      &-new {
        position: absolute;
        right: 12%;
        top: 5%;
      }
    }

    input {
      display: block;
      height: 8%;
      width: 80%;
      padding: 1rem;
      background-color: #181818;
      color: white;
      border: 1px solid white;
      &:focus {
        outline: none;
        border: 1px solid white;
      }
    }
    &-data {
      width: 80%;
      display: block;
      height: 50vh;
      background-color: #181818;
      overflow-y: scroll;
      padding: 1rem;
      border: 1px solid white;
    }
  }
}

@media only screen and (max-width: 768px) {
  .content-search {
    position: absolute;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &-close {
      position: absolute;
      right: 10%;
      top: 4%;
      &-new {
        position: absolute;
        right: 10%;
        top: 30%;
      }
    }

    input {
      display: block;
      height: 8%;
      width: 100%;
      padding: 1rem;
      background-color: #181818;
      color: white;
      border: 1px solid white;
      &:focus {
        outline: none;
        border: 1px solid white;
      }
    }
    &-data {
      width: 100%;
      display: block;
      height: 50vh;
      background-color: #181818;
      overflow-y: scroll;
      padding: 1rem;
      border: 1px solid white;
    }
  }

  .content-player {
    position: fixed;
    padding: 0 1rem;
    width: 80%;
    height: 8rem;
    bottom: 0;
    overflow: hidden;
    font-size: 10px;
  }
}
