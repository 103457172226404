@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.login-container{width:100vw;height:100vh;background-color:#181818;display:flex;flex-direction:column;align-items:center;justify-content:center}.login-container-head{color:white;padding:1rem}.login-container-link{color:white;background-color:green;text-decoration:none;padding:0.8rem 4rem;border-radius:0.7rem}

.sidebar{width:100%;height:100vh;background-color:black;padding:1rem;color:white}.sidebar-menu{margin-top:2rem}.sidebar-menu-list{list-style:none;cursor:pointer;padding:1rem 0}@media only screen and (max-width: 768px){.sidebar{width:100%;height:100vh;background-color:black;padding:1rem;color:white}.sidebar h1{font-size:12px}.sidebar-menu{margin-top:2rem}.sidebar-menu-list{list-style:none;cursor:pointer;font-size:8px;font-weight:bold;padding:1rem 0}}

.home{width:100vw;height:100vh;background-color:#181818;display:flex}.home-left{width:20vw}.home-right{width:80vw;overflow:hidden}.home-right-login{display:flex;align-items:center;justify-content:end;height:3rem;padding:1rem;color:green}.home-premium-error{position:absolute;width:100vw;height:100vh;background-color:rgba(0,0,0,0.8)}.home-premium-error div{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-color:white;width:30rem;height:10rem;text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:1rem;border-radius:0.5rem}.home-premium-error div button{background-color:green;box-shadow:none;border:none;padding:0.5rem 2rem;margin-top:1rem;border-radius:0.5rem;color:white}@media only screen and (max-width: 768px){.home-right-login{text-align:end;display:flex;justify-content:flex-end}.home-right-login p{font-size:12px}}

.content{width:80vh}.content-wrapper{width:100vw;height:90vh;padding:2rem;overflow-y:scroll}.content-header{color:white;margin:1rem 0}.content-playlist{width:100%;display:flex;overflow-x:scroll}.content-player{position:fixed;padding:0 1rem;width:80%;height:4rem;bottom:0;overflow:hidden}.content-search{position:absolute;width:60%;display:flex;flex-direction:column;align-items:center;justify-content:center;border:none}.content-search-close{position:absolute;right:12%;top:5%}.content-search-close-new{position:absolute;right:12%;top:5%}.content-search input{display:block;height:8%;width:80%;padding:1rem;background-color:#181818;color:white;border:1px solid white}.content-search input:focus{outline:none;border:1px solid white}.content-search-data{width:80%;display:block;height:50vh;background-color:#181818;overflow-y:scroll;padding:1rem;border:1px solid white}@media only screen and (max-width: 768px){.content-search{position:absolute;width:70%;display:flex;flex-direction:column;align-items:center;justify-content:center;border:none}.content-search-close{position:absolute;right:10%;top:4%}.content-search-close-new{position:absolute;right:10%;top:30%}.content-search input{display:block;height:8%;width:100%;padding:1rem;background-color:#181818;color:white;border:1px solid white}.content-search input:focus{outline:none;border:1px solid white}.content-search-data{width:100%;display:block;height:50vh;background-color:#181818;overflow-y:scroll;padding:1rem;border:1px solid white}.content-player{position:fixed;padding:0 1rem;width:80%;height:8rem;bottom:0;overflow:hidden;font-size:10px}}

*{padding:0;margin:0;box-sizing:border-box;font-family:'Poppins', sans-serif}.play-card{background-color:black;margin:0.5rem;border-radius:0.5rem;padding:1rem}.play-card-img{width:200px;height:200px;border-radius:0.5rem}.play-card-artist{color:white}.last-child{margin-bottom:5rem}.search-card{display:flex;align-items:start;border-bottom:1px solid white;padding:0.5rem;cursor:pointer}.search-card-img{width:50px;height:50px;border-radius:0.5rem}.search-card-artist{color:white}.search-card-album{color:white;font-size:10px}.search-card-wrapper{margin-left:1rem}@media only screen and (max-width: 768px){.play-card{background-color:black;margin:0.5rem;border-radius:0.5rem;padding:1rem}.play-card-img{width:100px;height:100px;border-radius:0.5rem}.play-card-artist{color:white;font-size:8px}.last-child{margin-bottom:5rem}.search-card{display:flex;align-items:start;border-bottom:1px solid white;padding:0.5rem;cursor:pointer}.search-card-img{width:50px;height:50px;border-radius:0.5rem}.search-card-artist{color:white;font-size:8px}.search-card-album{color:white;font-size:6px}.search-card-wrapper{margin-left:1rem}}::-webkit-scrollbar{display:none}html{-ms-overflow-style:none}

