.home {
  width: 100vw;
  height: 100vh;
  background-color: #181818;
  display: flex;
  &-left {
    width: 20vw;
  }
  &-right {
    width: 80vw;
    overflow: hidden;
    &-login {
      display: flex;
      align-items: center;
      justify-content: end;
      height: 3rem;
      padding: 1rem;
      color: green;
    }
  }
  &-premium-error {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.8);
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      width: 30rem;
      height: 10rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-radius: 0.5rem;
      button {
        background-color: green;
        box-shadow: none;
        border: none;
        padding: 0.5rem 2rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-right-login {
    text-align: end;
    display: flex;
    justify-content: flex-end;
    p {
      font-size: 12px;
    }
  }
}
