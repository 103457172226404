@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.play-card {
  background-color: black;
  margin: 0.5rem;
  border-radius: 0.5rem;
  padding: 1rem;

  &-img {
    width: 200px;
    height: 200px;
    border-radius: 0.5rem;
  }
  &-artist {
    color: white;
  }
}

.last-child {
  margin-bottom: 5rem;
}

.search-card {
  display: flex;
  align-items: start;
  border-bottom: 1px solid white;
  padding: 0.5rem;
  cursor: pointer;
  &-img {
    width: 50px;
    height: 50px;
    border-radius: 0.5rem;
  }
  &-artist {
    color: white;
  }
  &-album {
    color: white;
    font-size: 10px;
  }
  &-wrapper {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .play-card {
    background-color: black;
    margin: 0.5rem;
    border-radius: 0.5rem;
    padding: 1rem;

    &-img {
      width: 100px;
      height: 100px;
      border-radius: 0.5rem;
    }
    &-artist {
      color: white;
      font-size: 8px;
    }
  }

  .last-child {
    margin-bottom: 5rem;
  }

  .search-card {
    display: flex;
    align-items: start;
    border-bottom: 1px solid white;
    padding: 0.5rem;
    cursor: pointer;
    &-img {
      width: 50px;
      height: 50px;
      border-radius: 0.5rem;
    }
    &-artist {
      color: white;
      font-size: 8px;
    }
    &-album {
      color: white;
      font-size: 6px;
    }
    &-wrapper {
      margin-left: 1rem;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
}
